import Vue from 'vue'
import VueRouter from 'vue-router'
import Vant, {Dialog} from 'vant';
import "vant/lib/index.css"
import App from '../App'
import * as path from "path";

Vue.use(Vant)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: App
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('../error')
    },
    {
        path: '/index',
        redirect: App
    }, {
        path: '/admin/login',
        name: 'AdminLogin',
        component: () => import('../views/admin/login/login.vue'),
        meta: {is_show: true},
    }, {
        path: '/change_password',
        name: 'ChangePassword',
        component: () => import('../views/admin/admin_layout.vue'),
        redirect: '/change_password/index',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'index',
                name: 'ChangePasswordIndex',
                component: () => import('../views/admin/login/change.vue'),
                meta: {requiresAuth: true}
            },
        ]
    }, {
        path: '/admin',
        redirect: '/admin/home_page/index',
        meta: {requiresAuth: true}
    },
    {
        path: '/h5/pay',
        name: 'Pay',
        component: () => import('../views/h5/pay/pay'),
    },
    {
        path: '/h5/login',
        name: 'Login',
        component: () => import('../views/h5/login'),
        meta: {h5RequiresAuth: false},
    },
    {
        path: '/h5/new_password',
        name: 'NewPassword',
        component: () => import('../views/h5/mine/new_password'),
        meta: {h5RequiresAuth: false},
    },
    {
        path: '/h5/register',
        name: 'Register',
        component: () => import('../views/h5/register'),
    },
    {
        path: '/h5/apply',
        name: 'Apply',
        component: () => import('../views/h5/apply/apply'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/apply/msgverify',
        name: 'MsgVerify',
        component: () => import('../views/h5/apply/msgverify'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/apply/moneyverify',
        name: 'MoneyVerify',
        component: () => import('../views/h5/apply/moneyverify'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/applying',
        name: 'Applying',
        component: () => import('../views/h5/applying'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/tradelist',
        name: 'Tradelist',
        component: () => import('../views/h5/tradelist'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/subtradelist',
        name: 'SubTradelist',
        component: () => import('../views/h5/smer_tradelist'),
        meta: {h5RequiresAuth: true},
    },

    {
        path: '/h5/tradedetail',
        name: 'Tradedetail',
        component: () => import('../views/h5/tradedetail'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/getcash',
        name: 'Getcash',
        component: () => import('../views/h5/pay/getcash'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/getcash_history',
        name: 'GetcashHistory',
        component: () => import('../views/h5/pay/getcash_history'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine',
        name: 'Mine',
        component: () => import('../views/h5/mine'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine/base',
        name: 'Baseinfo',
        component: () => import('../views/h5/mine/user_info'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine/bankinfo',
        name: 'Bankinfo',
        component: () => import('../views/h5/mine/bank_info'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine/merchants',
        name: 'MyMerchants',
        component: () => import('../views/h5/mine/my_merchants'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine/merchant/info',
        name: 'MerchantInfo',
        component: () => import('../views/h5/mine/merchant_info'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine/approve',
        name: 'ApporveMerchants',
        component: () => import('../views/h5/mine/merchant_approve'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/mine/approve/info',
        name: 'ApporveMerchantsInfo',
        component: () => import('../views/h5/mine/approve_info'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/h5/home',
        name: 'Home',
        component: () => import('../views/h5/home'),
        meta: {h5RequiresAuth: true},
    },
    {
        path: '/admin/home_page',
        component: () => import('../views/admin/admin_layout.vue'),
        name: 'AdminHomePage',
        redirect: '/admin/home_page/index',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'index',
                name: 'AdminHomePageIndex',
                component: () => import('../views/admin/home_page'),
                meta: {requiresAuth: true}
            },
        ]
    },
    {
        path: '/admin/admin_manage',
        component: () => import('../views/admin/admin_layout.vue'),
        name: 'adminManage',
        redirect: '/admin/company_manage/index',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'index',
                name: 'adminManageIndex',
                component: () => import('../views/admin/admin_manage/index'),
                meta: {requiresAuth: true, asideMenu: 'adminManage'}
            }, {
                path: 'new',
                name: 'adminManageNew',
                component: () => import('../views/admin/admin_manage/new'),
                meta: {requiresAuth: true, asideMenu: 'adminManage'}
            }, {
                path: 'edit/:id',
                name: 'adminManageEdit',
                component: () => import('../views/admin/admin_manage/edit.vue'),
                meta: {requiresAuth: true, asideMenu: 'adminManage'}
            }
        ]
    },
    {
        path: '/admin/merchant_verify',
        component: () => import('../views/admin/admin_layout.vue'),
        name: 'merchantVerify',
        redirect: '/admin/merchant_verify/index',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'index',
                name: 'merchantVerifyIndex',
                component: () => import('../views/admin/merchant_verify/index'),
                meta: {requiresAuth: true, asideMenu: 'merchantVerify'}
            }, {
                path: 'new',
                name: 'merchantNew',
                component: () => import('../views/admin/merchant_verify/new'),
                meta: {requiresAuth: true, asideMenu: 'merchantVerify'}
            }, {
                path: 'watch/:id',
                name: 'merchantDraftWatch',
                component: () => import('../views/admin/merchant_verify/watch.vue'),
                meta: {requiresAuth: true, asideMenu: 'merchantVerify'}
            }
        ]
    },
    {
        path: '/admin/merchant_manage',
        component: () => import('../views/admin/admin_layout.vue'),
        name: 'merchantManage',
        redirect: '/admin/merchant_manage/index',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'index',
                name: 'merchantManageIndex',
                component: () => import('../views/admin/merchant_manage/index'),
                meta: {requiresAuth: true, asideMenu: 'merchantManage'}
            }, {
                path: 'watch/:id',
                name: 'merchantWatch',
                component: () => import('../views/admin/merchant_manage/watch.vue'),
                meta: {requiresAuth: true, asideMenu: 'merchantManage'}
            }, {
                path: 'edit/:id',
                name: 'merchantManageEdit',
                component: () => import('../views/admin/merchant_manage/edit.vue'),
                meta: {requiresAuth: true, asideMenu: 'merchantManage'}
            }
        ]
    },
    {
        path: '/admin/order_manage',
        component: () => import('../views/admin/admin_layout.vue'),
        name: 'orderManage',
        redirect: '/admin/order_manage/index',
        meta: {requiresAuth: true},
        children: [
            {
                path: 'index',
                name: 'orderManageIndex',
                component: () => import('../views/admin/order_manage/index'),
                meta: {requiresAuth: true, asideMenu: 'orderManage'}
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes
})

router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token');
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (token) {
            next()
        } else {
            next({path: '/admin/login'})
        }
    } else if (to.matched.some(record => record.meta.h5RequiresAuth)) {
        if (token) {
            if (to.path == '/h5/applying') {
                next()
                return;
            }
            if (from.path == '/h5/login' && to.path == '/h5/home') {
                // console.log('before apply   ' + to.path);
                let ja = localStorage.getItem('jump_path');
                let jq = localStorage.getItem('jump_query');
                if (ja !== undefined && ja != null && ja.length > 0) {
                    localStorage.setItem('jump_path', '');
                    localStorage.setItem('jump_query', '');
                    next({path: ja, query: jq})
                    return;
                }
            }
            let merId = localStorage.getItem("merchantId")
            let draftId = localStorage.getItem("draftId")
            let merchantDraftStatus = localStorage.getItem("merchantDraftStatus")
            // console.log(from.path)
            if (merId.length > 0 && merId != "null") {
                next()
            } else {
                if (draftId && draftId.length > 0 && draftId != "null") {
                    // 这里判断具体状态
                    switch (merchantDraftStatus) {
                        case "1":

                            if (from.path != '/h5/apply' && to.path != '/h5/apply') {
                                // console.log('in status = 1 != apply  ' + from.path+'--->'+to.path);
                                next({path: '/h5/apply'})
                            } else {
                                next()
                            }
                            break;
                        case "2":
                            if (to.path != '/h5/applying') {
                                next({path: '/h5/applying'})
                            } else {
                                next()
                            }
                            break;
                        case "3":
                            if (to.path == '/h5/apply') {
                                next()
                                break;
                            }
                            Dialog.confirm({
                                title: '提示',
                                message:
                                    '您提交的商户申请未通过审核，请修改后重新提交。',
                            })
                                .then(() => {
                                    // console.log(from.path);
                                    if (to.path != '/h5/apply') {
                                        next({path: '/h5/apply'})
                                    } else if (from.path == '/h5/login') {
                                        next()
                                    }
                                })
                                .catch(() => {
                                });
                            break;
                        case "4":
                            if (to.path != '/h5/apply/msgverify') {
                                next({path: '/h5/apply/msgverify'})
                            } else {
                                next()
                            }
                            break;
                        case "5":
                            if (to.path != '/h5/apply/moneyverify') {
                                next({path: '/h5/apply/moneyverify'})
                            } else {
                                next()
                            }
                            break;
                        default:
                            next()
                            break;
                    }
                } else {

                    if (to.query.merchantId) {
                        // console.log(to.query.merchantId)
                        next()
                    } else {
                        Dialog.confirm({
                            title: '',
                            message:
                                '您尚未申请商户账号，请扫描邀请码申请',
                        }).then(() => {
                            next({path: '/h5/login'})
                        }).catch(() => {
                        });
                    }

                }
            }
        } else {
            localStorage.setItem("jump_path", to.path);
            localStorage.setItem("jump_query", to.query);
            // console.log('000000')
            // console.log(to.path);
            next({path: '/h5/login'})
        }
    } else {
        next()
    }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}


export default router

