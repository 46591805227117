import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// // 以下为语言包单独设置的场景，单独设置时语言包需单独引入
// const messages = {
//   'zh_cn': require('../i18n/Menus_cn.json'),   // 中文语言包
//   'en_us': require('../i18n/Menus_en.json')    // 英文语言包
// }
//
// // 最后 export default，这一步肯定要写的。
export default new VueI18n({
//  // set locale 默认显示中文
//   locale : (function() {
//     if (localStorage.getItem('lang')) {
//       return localStorage.getItem('lang')
//     } else {
//       return 'zh_cn'
//     }
//   })(),
//   messages : messages // set locale messages
})