import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentUser: null,
        showQR: false,
        token: localStorage.getItem("token"),
        merchantId: localStorage.getItem('merchantId'),
        merchantName: localStorage.getItem('merchantName'),
        merchantLogo: localStorage.getItem('merchantLogo'),
        merchantLogoUrl: localStorage.getItem('merchantLogoUrl'),
        role_id: localStorage.getItem('role_id'),
        role_name: localStorage.getItem('role_name'),
        admin_name: localStorage.getItem("admin_name"),
        admin_id: localStorage.getItem("admin_id"),
        level: localStorage.getItem("level"),
        type: localStorage.getItem("type")
    },
    mutations: {
        setCurrentUser(state, currentUser) {
            localStorage.setItem("token", currentUser.token ? currentUser.token : null);
            localStorage.setItem("merchantId", currentUser.merchantId ? currentUser.merchantId : null);
            localStorage.setItem("merchantName", currentUser.merchantName ? currentUser.merchantName : null);
            localStorage.setItem("merchantLogo", currentUser.merchantLogoUrl ? currentUser.merchantLogoUrl : null);
            localStorage.setItem("admin_id", currentUser.id ? currentUser.id : null);
            localStorage.setItem("admin_name", currentUser.username ? currentUser.username : null);
            localStorage.setItem("role_id", currentUser.role_id ? currentUser.role_id : null);
            localStorage.setItem("role_name", currentUser.role_name ? currentUser.role_name : null);
            localStorage.setItem("level", currentUser.level ? currentUser.level : null);
            localStorage.setItem("type", currentUser.type ? currentUser.type : null);//1-和上，2-寺庙，3-商户，4-部门"

            state.level = currentUser.level ? currentUser.level : null;
            state.token = currentUser.token ? currentUser.token : null;
            state.currentUser = currentUser;
            state.merchantId = currentUser.merchantId ? currentUser.merchantId : null;
            state.merchantName = currentUser.merchantName ? currentUser.merchantName : null;
            state.merchantLogo = currentUser.merchantLogoUrl ? currentUser.merchantLogoUrl : null;
            state.merchantLogoUrl = currentUser.merchantLogoUrl ? currentUser.merchantLogoUrl : null;

            state.role_id = currentUser.role_id ? currentUser.role_id : null;
            state.role_name = currentUser.role_name ? currentUser.role_name : null;
            state.admin_id = currentUser.id ? currentUser.id : null;
            state.admin_name = currentUser.username ? currentUser.username : null;
            state.type = currentUser.type ? currentUser.type : null;

        },
        logout(state) {
            state.token = null;
            state.merchantName = null;
            state.merchantId = null;
            state.merchantName = null
            state.merchantLogo = null
            state.role_name = null;
            state.role_id = null;
            state.currentUser = null;
            state.admin_id = null;
            state.admin_name = null;
            state.level = null;
            state.type = null;

            localStorage.removeItem('level')
            localStorage.removeItem('token')
            localStorage.removeItem('merchantId')
            localStorage.removeItem('merchantName')
            localStorage.removeItem('merchantLogo')
            localStorage.removeItem('role_name')
            localStorage.removeItem('role_id')
            localStorage.removeItem('admin_id')
            localStorage.removeItem('admin_name')
            localStorage.removeItem('type')
            localStorage.removeItem('draftId')

        },
        inputQRView(state, payload) {
            state.showQR = payload
        },
    }
})
